import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { AnimadetPage } from "../../templates/animated-page/AnimadetPage";
import { Toggle } from "../../atoms/toggle/Toggle";
import { Avatar, Form, Space } from "antd";
import User from "../../../images/User.svg";
import Clock2 from "../../../images/Clock2.svg";
import { P } from "../../atoms/paragraph/Paragraph.styled";
import {
  createFormInitialValue,
  createShema,
  joiResolver,
} from "../../../utils";
import { FormProvider, useForm } from "react-hook-form";
import { SupportedControls } from "../../molecules/supported-controls/SupportedControls";
import { motion } from "framer-motion";
import { useAppContext } from "../../../contexts/useAppContext";

import { GoToSummary } from "../../molecules/go-to-summary/GoToSummary";
import useScrollToTop from "../../../hooks/useScrollToTop";
import { useLocation } from "react-router-dom";

const MealsWrapper = styled.div`
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 20px;
  padding-bottom: 20px;
  min-height: calc(100vh - 142px);
`;

const Meal = styled.div`
  border: 1px solid #f3f4f6;
  border-radius: 24px;
  margin-bottom: 10px;
  background: white;
  box-shadow: 1px 3px 0px 0px #edebfb;
  padding: 16px;
`;

export const DeliveryPage: React.FC = () => {
  useScrollToTop();
  const location = useLocation();

  const { deliveryType, selectedBranch } = useAppContext();

  const isOrder = !!location?.state?.isOrder;
  const { sections = [] } = isOrder
    ? selectedBranch?.config?.delivery_controls?.orders
    : selectedBranch?.config?.delivery_controls?.service;

  const controls = sections
    .map((e: any) => e.controls)
    .flat()
    .filter(
      (control: any) =>
        control?.deliveryType?.includes(deliveryType) || !isOrder
    );

  const deliveryControls = useMemo(
    () => controls ?? [],
    [selectedBranch, deliveryType]
  );
  const schema = createShema([...deliveryControls], deliveryType);
  console.log('deliveryControls',deliveryControls,schema)

  const methods = useForm({
    resolver: joiResolver(schema),
  });

  useEffect(() => {
    if (deliveryControls.length > 0) {
      deliveryType && methods.reset();
      methods.reset(createFormInitialValue([...deliveryControls]));
    }
  }, [deliveryControls, deliveryType]);

  const [removeFromDOM, setRemoveFromDOM] = useState(true);
  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    if (deliveryType) {
      setIsFirstRender(false);
      setTimeout(() => {
        setRemoveFromDOM(true);
      }, 200);
    } else {
      setRemoveFromDOM(false);
    }
  }, [deliveryType]);

  // if (isFetching) {
  //   return null;
  // }

  return (
    <>
      <FormProvider {...methods}>
        <AnimadetPage>
          <MealsWrapper>
            <Meal>
              <Toggle />
            </Meal>
            {sections.map((section: any) => {
              return (
                <Meal>
                  <Space
                    style={{
                      marginBottom: "20px",
                    }}
                  >
                    <Avatar
                      shape="square"
                      style={
                        {
                          // background: "#eef2ff",
                        }
                      }
                      src={User}
                    />
                    <P $size="16" $weight="400" $height="20">
                      {section.section.name}
                    </P>
                  </Space>

                  <Form layout="vertical">
                    {section?.controls?.map((control: any) => {
                      if (
                        control?.deliveryType?.includes(deliveryType) ||
                        !isOrder
                      )
                        return (
                          <motion.div
                            animate={{
                              scale:
                                !control?.deliveryType?.includes(
                                  deliveryType
                                ) && isOrder
                                  ? 0
                                  : 1,
                              height:
                                !control?.deliveryType?.includes(
                                  deliveryType
                                ) && isOrder
                                  ? 0
                                  : "auto",
                            }}
                            transition={{ duration: 0.2 }}
                            initial={{
                              scale: isFirstRender ? 1 : 0,
                              height: isFirstRender ? "auto" : 0,
                            }}
                          >
                            <SupportedControls
                              control={control}
                              key={control.id}
                            />
                          </motion.div>
                        );
                    })}
                  </Form>
                </Meal>
              );
            })}
          </MealsWrapper>
        </AnimadetPage>
        <GoToSummary sections={sections} isOrder={isOrder} />
      </FormProvider>
    </>
  );
};

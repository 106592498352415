import { Button, Flex } from "antd";
import React from "react";
import styled from "styled-components";
import { P } from "../../atoms/paragraph/Paragraph.styled";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../../contexts/useAppContext";
import { useFormContext } from "react-hook-form";
import { saveiInStorage } from "../../../utils";
import { createMealOptionFrontView } from "../add-meal-action/AddMealAction.utils";

const GoToDeliveryButtonWrapper = styled.div`
  width: 100%;

  bottom: 0;
  left: 0;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
  background: #f3f4f6;
  border-top: 1px solid #e9eaee;
`;

const CostumAddButton = styled(Button)`
  max-width: 100% !important;
  width: 100% !important;
  height: 48px !important;
  font-size: 18px;
  border-radius: 40px;
  color: white;
  background: #4f46e5;
  border-color: #4f46e5;

  &:hover {
    color: white !important;
    background: #4f46e595 !important;
    border-color: #4f46e595 !important;
  }
`;

export const GoToSummary: React.FC<{ sections: any; isOrder: any }> = ({
  sections,
  isOrder,
}) => {
  const navigate = useNavigate();
  const methods = useFormContext();
  const { setAdditionalSections, deliveryType, deliveryAdress, email, name, number } =
    useAppContext();
  const submit = () => {
    if (number === "" || name === "" || email === "") {
      alert("uzupełnij wszyskite wymagane pola");
      return;
    } else {
      localStorage.setItem("email", email);
      localStorage.setItem("name", name);
      localStorage.setItem("number", number);
    }
    
    if (deliveryAdress || deliveryType === "SELF_PICKUP" || !isOrder) {
      if (deliveryAdress)
        localStorage.setItem("place_id", deliveryAdress?.value);

      methods.handleSubmit((data: any) => {
        const additionalSections = createMealOptionFrontView(sections, data);
        setAdditionalSections(additionalSections);
        saveiInStorage(data);
        navigate("/summary", { state: { from: "home", isOrder: isOrder } });
      }, console.error)();
    } else {
      alert("wybierz miejsce dostawy");
    }
  };

  return (
    <GoToDeliveryButtonWrapper>
      <Flex
        vertical
        justify="space-between"
        style={{
          maxWidth: "450px",
          width: "100%",
        }}
      >
        <Flex
          justify="space-between"
          gap={20}
          style={{
            maxWidth: "450px",
            width: "100%",
          }}
        >
          <CostumAddButton onClick={submit}>
            <P $size="16" $height="20" $weight="400">
              Przejdz do podsumowania
            </P>
          </CostumAddButton>
        </Flex>
      </Flex>
    </GoToDeliveryButtonWrapper>
  );
};

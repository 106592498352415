import styled from "styled-components";
import { P } from "../paragraph/Paragraph.styled";
import { WalletOutlined } from "@ant-design/icons";
import P24 from '../../../images/Przelewy24_logo.svg'
const PaymentOptionWrapper = styled.div<{ $selected: boolean }>`
  width: 150px;
  height: 150px;
  border-radius: 8px;
  min-height: 64px;
  cursor: pointer;
  padding: 12px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  outline: ${({ $selected }) =>
    $selected ? "2px solid #4f46e5" : "1px solid #d1d5db"};
  background: white;
`;

const PaymentIcon = styled.div`
  width: 100%;
  height: 95px;
  border-radius: 8px;
  min-height: 64px;
  cursor: pointer;
  padding: 12px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f2f2f7;
`;

export const PaymentOption: React.FC<any> = ({
  option,
  selectedOptions,
  setSelectedOptions,
}) => {
  const isSelected = option?.id === selectedOptions?.id;
  return (
    <>
      <PaymentOptionWrapper
        $selected={isSelected}
        onClick={() => setSelectedOptions(option)}
      >
        <PaymentIcon>
          {
            option.type === 'OFFLINE' ?     <WalletOutlined
            style={{
              fontSize: 25,
            }}
          />: <img src={P24} width={80}/>
          }
     
        </PaymentIcon>
        <P $height="20" $size="14" $weight="400" $family="">
          {option.name}
        </P>
      </PaymentOptionWrapper>
    </>
  );
};

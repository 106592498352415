import React, { useState } from "react";
import { AnimadetPage } from "../../templates/animated-page/AnimadetPage";
import styled from "styled-components";

import useScrollToTop from "../../../hooks/useScrollToTop";
import { useAppContext } from "../../../contexts/useAppContext";
import { SetResourcesAction } from "../../molecules/set-resources-action/SetResourcesAction";
import { useQueries, useQuery } from "react-query";
import { getCategoryResources, getResourceCategories } from "../../../service";
import { LoadingScreen } from "../../templates/loading-screen/LoadingScreen";
import { ResourceSelect } from "../../atoms/resource-select/ResourceSelect";

const DetailsWrapper = styled.div`
  padding-bottom: 110px;
  padding-top: 20px;
  padding-left: 16px;
  padding-right: 16px;
  background: white;
  min-height: calc(100vh + 110px);
`;

export const SelectResource: React.FC = () => {
  useScrollToTop();
  const { serviceData } = useAppContext();
  const [resourceCategories, setResourceCategories] = useState([]);

  const { isFetching: isFetchingResourceCategories } = useQuery(
    ["/resource-categories"],
    () => getResourceCategories(),
    {
      onSuccess(data) {
        setResourceCategories(
          data.map((e: any) => {
            return {
              id: e.id,
              name: e.name,
            };
          })
        );
      },
    }
  );

  const queries = useQueries(
    resourceCategories.map((e: any) => ({
      queryKey: ["data", e.id],
      queryFn: () => getCategoryResources({ id: e.id }),
      enabled: resourceCategories.length > 0,
    }))
  );
  const isLoading = queries.map((e) => e.isLoading).includes(true);
  const data = queries.map((e) => e.data);
  
  return (
    <>
      {!isLoading || !isFetchingResourceCategories ? (
        <>
          <AnimadetPage>
            <DetailsWrapper>
              {resourceCategories.map((category: any) => {
                return (
                  <>
                    <p>{category.name}</p>
                    <ResourceSelect id={category.id} categoryName={category.name} data={data}/>
                  </>
                );
              })}
            </DetailsWrapper>
          </AnimadetPage>
          <SetResourcesAction />
        </>
      ) : (
        <LoadingScreen />
      )}
    </>
  );
};

import React from "react";
import styled from "styled-components";

import { GoToMenu } from "../../molecules/go-to-menu/GoToMenu";
import { BranchHeader } from "../../organisms/branch-header/BranchHeader";

const DetailsWrapper = styled.div`
  padding-top: 230px;
  padding-bottom: 220px;
  padding-left: 20px;
  padding-right: 20px;
  background: #f3f4f6;
  min-height: 100vh;
`;

export const StatutePage: React.FC = () => {
  return (
    <>
      <BranchHeader />
      <DetailsWrapper />
      <GoToMenu />
    </>
  );
};

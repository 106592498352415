import React, { useEffect } from "react";
import { AnimadetPage } from "../../templates/animated-page/AnimadetPage";
import styled from "styled-components";

import { useQuery } from "react-query";
import { getDetails } from "../../../service";
import { useParams } from "react-router-dom";

import { LoadingScreen } from "../../templates/loading-screen/LoadingScreen";
import useScrollToTop from "../../../hooks/useScrollToTop";
import { SetDataAction } from "../../molecules/set-data-action/SetDataAction";
import { DataPickerCarusel } from "../../atoms/data-picker-carusel/DataPickerCarusel";
import { P } from "../../atoms/paragraph/Paragraph.styled";
import { OrderTimePickerList } from "../../atoms/order-time-picker-list/OrderTimePickerList";
import { useAppContext } from "../../../contexts/useAppContext";

const DetailsWrapper = styled.div`
  padding-bottom: 110px;
  padding-top: 20px;
  padding-left: 16px;
  padding-right: 16px;
  background: white;
  min-height: calc(100vh + 110px);
`;

export const OrderDataPicker: React.FC = () => {
  useScrollToTop();
  const { orderDate, setOrderDate, setOrderTime, setDeliveryTimeType } =
    useAppContext();

  useEffect(() => {
    setOrderDate(null);
    setOrderTime(null);
    setDeliveryTimeType("NOW");
  }, []);

  return (
    <>
      <AnimadetPage>
        <DetailsWrapper>
          <P
            $size="12"
            $height="16"
            $weight="400"
            style={{ marginBottom: "10px" }}
          >
            Data
          </P>
          <DataPickerCarusel days={5} isOrder={true} />
          {orderDate && (
            <>
              <P
                $size="12"
                $height="16"
                $weight="400"
                style={{ marginBottom: "10px" }}
              >
                Godzina
              </P>
              <OrderTimePickerList />
            </>
          )}
        </DetailsWrapper>
      </AnimadetPage>
      <SetDataAction isOrder={true} />
    </>
  );
};

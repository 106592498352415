import { createContext } from "react";

interface AppContextProps {
  cart: any;
  setCart: any;
  mealName: any;
  setMealName: any;
  selectedBranch: any;
  setSelectedBranch: any;
  deliveryType: any;
  setDeliveryType: any;
  additionalSections: any;
  setAdditionalSections: any;
  serviceData: any;
  setServiceData: any;
  serviceTime: any;
  setServiceTime: any;
  selectedCategory: any;
  setSelectedCategory: any;
  deliveryTimeType: any;
  setDeliveryTimeType: any;
  orderDate: any;
  setOrderDate: any;
  orderTime: any;
  setOrderTime: any;
  deliveryAdress: any;
  setDeliveryAdress: any;
  apertmenNumber: any;
  setApertmenNumber: any;
  deliveryNote: any;
  setDeliveryNote: any;
  selectedDeliveryMethod: any;
  setSelectedDeliveryMethod: any;
  name: any;
  setName: any;
  email: any;
  setEmail: any;
  number: any;
  setNumber: any;
  serviceResurces: any;
  setServiceResurces: any;
}

export const AppContext = createContext<AppContextProps | undefined>(undefined);

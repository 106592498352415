import React, { useState } from "react";
import locale_pl from "dayjs/locale/pl";
import dayjs from "dayjs";
import styled from "styled-components";
import { P } from "../paragraph/Paragraph.styled";
import { useAppContext } from "../../../contexts/useAppContext";
import { useQuery } from "react-query";
import { getAvailabilityOnDate } from "../../../service";
import { useParams } from "react-router-dom";
import { LoadingScreen } from "../../templates/loading-screen/LoadingScreen";
import { Flex, Select, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

dayjs.locale(locale_pl);

const DataWrapper = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
`;

export const ResourceSelect: React.FC<any> = ({ id, categoryName, data }) => {
  const { setServiceResurces, serviceResurces } = useAppContext();

  const options = data
    ?.find((e: any) => e?.[0]?.resourceCategoryId === id)
    ?.map((e: any) => {
      return {
        value: e?.id,
        label: e?.name,
      };
    });

  options?.push({
    value: 0,
    label: "Obojętne",
  });

  const onSelectChandler = (value: any) => {
    setServiceResurces((current: any) => {
      return {
        ...current,
        [`${id}`]: {
          ...options?.find((e: any) => e?.value === value),
          categoryName: categoryName,
        },
      };
    });
  };
  
  const value = serviceResurces[`${id}`]?.value;
  return (
    <Select
      style={{
        width: "100%",
      }}
      value={value}
      onSelect={onSelectChandler}
      defaultValue={0}
      options={options}
    />
  );
};

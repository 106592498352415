import { Flex, Form, Input, Select } from "antd";
import "./index.css";
import { motion } from "framer-motion";
import { P } from "../paragraph/Paragraph.styled";
import { useAppContext } from "../../../contexts/useAppContext";
import { GoogleMaps } from "../google-maps/GoogleMaps";
import { DeliveryTimePicker } from "../delivery-time-picker/DeliveryTimePicker";
import { useLocation } from "react-router-dom";
import { useQuery } from "react-query";
import { getDeliveryMethods } from "../../../service";
import { useEffect, useState } from "react";
import { ChangeReservationDate } from "../change-reservation-date/ChangeReservationDate";
import { ChangeResources } from "../change-resources/ChangeResources";

export const Toggle = () => {
  const {
    deliveryType,
    setDeliveryType,
    selectedBranch,
    selectedDeliveryMethod,
    setSelectedDeliveryMethod,
    name,
    setName,
    email,
    setEmail,
    number,
    setNumber,
  } = useAppContext();
  const location = useLocation();
  const isOrder = !!location?.state?.isOrder;
  const textVariants = {
    initial: { color: "#f3f4f6" }, // Initial color (e.g., black)
    animate: {
      color: "#4f46e5", // New color (e.g., red)
      transition: { duration: 1 }, // Duration of the animation in seconds
    },
  };

  const textVariants2 = {
    initial: { color: "#4f46e5" }, // Initial color (e.g., black)
    animate: {
      color: "#f3f4f6", // New color (e.g., red)
      transition: { duration: 1 }, // Duration of the animation in seconds
    },
  };

  const id = selectedBranch?.id;
  const [options, setOptions] = useState([]);
  const [allOptions, setAllOptions] = useState([]);

  useQuery(["/delivery-methods", id], () => getDeliveryMethods({ id }), {
    onSuccess(data) {
      setAllOptions(data);
      const opt = data
        .filter((e: any) => e.type === deliveryType)
        .map((e: any) => {
          return {
            value: e.id,
            label: e.name,
          };
        });
      setOptions(opt);
      if (opt?.length === 1) {
        const options = data.find(
          (e: any) => e.id.toString() === opt[0]?.value.toString()
        );
        setSelectedDeliveryMethod(options);
      } else {
        setSelectedDeliveryMethod(null);
      }
    },
    enabled: !!id && isOrder,
  });

  useEffect(() => {
    const opt: any = allOptions
      .filter((e: any) => e.type === deliveryType)
      .map((e: any) => {
        return {
          value: e.id,
          label: e.name,
        };
      });
    setOptions(opt);
    if (opt?.length === 1) {
      const options = allOptions.find(
        (e: any) => e.id.toString() === opt[0]?.value.toString()
      );
      setSelectedDeliveryMethod(options);
    } else {
      setSelectedDeliveryMethod(null);
    }
  }, [deliveryType]);

  const onSelect = (value: string | number) => {
    const options = allOptions.find(
      (e: any) => e.id.toString() === value.toString()
    );
    setSelectedDeliveryMethod(options);
  };

  // if (allOptions?.length > 0)
  return (
    <>
      {isOrder && (
        <>
          <Flex justify="center">
            <div
              style={{
                position: "relative",
              }}
            >
              <label
                htmlFor="delivery-type"
                style={{
                  position: "absolute",
                  top: "15px",
                  left: "73px",
                  zIndex: 1000,
                }}
              >
                <motion.div
                  initial="initial"
                  animate={
                    deliveryType === "SELF_PICKUP" ? "animate" : "initial"
                  }
                  variants={textVariants}
                >
                  <P $size="11" $weight="400" $height="16">
                    Dostawa
                  </P>
                </motion.div>
              </label>
              <label
                htmlFor="delivery-type"
                style={{
                  position: "absolute",
                  top: "15px",
                  right: "50px",
                  zIndex: 1000,
                }}
              >
                <motion.div
                  initial="initial"
                  animate={
                    deliveryType === "SELF_PICKUP" ? "animate" : "initial"
                  }
                  variants={textVariants2}
                >
                  <P $size="11" $weight="400" $height="16">
                    Odbiór osobisty
                  </P>
                </motion.div>
              </label>
              <input
                type="checkbox"
                id="delivery-type"
                checked={deliveryType === "SELF_PICKUP"}
                onChange={(e) => {
                  setDeliveryType(
                    deliveryType === "SELF_PICKUP"
                      ? "TO_THE_ADDRESS"
                      : "SELF_PICKUP"
                  );
                }}
              />
            </div>
          </Flex>
          <Form layout="vertical">
            {options.length > 1 && (
              <Form.Item
                label={
                  deliveryType === "SELF_PICKUP"
                    ? "Opcje dostawy "
                    : "Opcje odbioru "
                }
              >
                <Select
                  options={options}
                  value={selectedDeliveryMethod?.id}
                  onSelect={onSelect}
                />
              </Form.Item>
            )}
          </Form>
        </>
      )}
      {isOrder && deliveryType === "TO_THE_ADDRESS" && (
        <>
          <GoogleMaps />
          <hr
            style={{
              marginBottom: "20px",
              width: "95%",
              background: "#e5e7eb",
              color: "#e5e7eb",
            }}
          />
        </>
      )}
      <Form layout="vertical">
        <Form.Item label="Imie i nazwisko" required>
          <Input
            style={{
              height: "40px",
            }}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Form.Item>
      </Form>
      <Form layout="vertical">
        <Form.Item label="E-mail" required>
          <Input
            style={{
              height: "40px",
            }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Item>
      </Form>
      <Form layout="vertical">
        <Form.Item label="Numer telefonu" required>
          <Input
            value={number}
            onChange={(e) => setNumber(e.target.value)}
            style={{
              height: "40px",
            }}
          />
        </Form.Item>
      </Form>
      <hr
        style={{
          marginBottom: "20px",
          width: "95%",
          background: "#e5e7eb",
          color: "#e5e7eb",
        }}
      />
      {isOrder && <DeliveryTimePicker />}
      {!isOrder && (
        <Form layout="vertical">
          <ChangeReservationDate
            control={{
              uuid: "date",
              field_name: "Termin",
              required: true,
            }}
          />
          <ChangeResources
            control={{
              uuid: "date",
              field_name: "Termin",
              required: true,
            }}
          />
        </Form>
      )}
    </>
  );
  return null;
};

import { Form, Checkbox as AntCheckbox, Button, Flex } from "antd";
import React, { useEffect } from "react";
import { useController, useFormContext } from "react-hook-form";
import { useAppContext } from "../../../contexts/useAppContext";
import { P } from "../paragraph/Paragraph.styled";
import { useNavigate } from "react-router-dom";

export const ChangeResources: React.FC<any> = () => {
  const navigate = useNavigate();
  const { serviceResurces } = useAppContext();
  const keys = Object.keys(serviceResurces).filter(
    (key) => serviceResurces[key]?.value > 0
  );
  return (
    <Form.Item label={"Zasoby"}>
      <Flex
        justify="space-between"
        style={{
          paddingTop: "7px",
        }}
      >
        <div>
          {keys.length > 0 ? (
            keys.map((resurceKey) => {
              if (serviceResurces[resurceKey]?.value)
                return (
                  <P $size="14" $weight="300" $height="20" $family="Lato-Light">
                    {`${serviceResurces[resurceKey].categoryName}: ${serviceResurces[resurceKey].label}`}
                  </P>
                );
            })
          ) : (
            <P $size="14" $weight="300" $height="20" $family="Lato-Light">
              Nie wybrano
            </P>
          )}
        </div>
        <Button
          onClick={() => {
            navigate(-2);
          }}
          type="link"
          style={{
            paddingRight: 0,
            color: "#4F46E5",
          }}
        >
          <P $size="16" $height="20" $weight="400">
            Zmień
          </P>
        </Button>
      </Flex>
    </Form.Item>
  );
};

import React, { useState } from "react";
import locale_pl from "dayjs/locale/pl";
import dayjs from "dayjs";
import styled from "styled-components";
import { P } from "../paragraph/Paragraph.styled";
import { useAppContext } from "../../../contexts/useAppContext";
import { useQuery } from "react-query";
import { getAvailabilityOnDate } from "../../../service";
import { useParams } from "react-router-dom";
import { LoadingScreen } from "../../templates/loading-screen/LoadingScreen";
import { Flex, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

dayjs.locale(locale_pl);

const DataWrapper = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
`;

const Data = styled.div<{ $selected: boolean }>`
  padding: 16px;
  min-width: 100px;
  border: 1px solid ${({ $selected }) => ($selected ? "#4f46e5" : "#d1d5db")};
  border-radius: 7px;
  cursor: pointer;
`;

export const OrderTimePickerList: React.FC = () => {
  const { id } = useParams();

  const { orderDate, orderTime, setOrderTime } = useAppContext();
  const [availabilityTime, setAvailabilityTime] = useState(["9:00", "10:00","11:00","12:00","13:00","14:00","15:00","16:00"]);


  if (orderDate) {
    return (
      <DataWrapper>
        {availabilityTime.map((data) => {
          return (
            <Data
              $selected={orderTime === data}
              onClick={() => setOrderTime(data)}
            >
              <P $size="14" $height="16" $weight="400">
                {data}
              </P>
            </Data>
          );
        })}
      </DataWrapper>
    );
  }
  return null;
};

import React from "react";
import { CategoriesSlider } from "../../molecules/categories-slider/CategoriesSlider";
import styled from "styled-components";
import { SingleMeal } from "../../molecules/single-meal/SingleMeal";
import { P } from "../../atoms/paragraph/Paragraph.styled";
import { AnimadetPage } from "../../templates/animated-page/AnimadetPage";
import { useQuery } from "react-query";
import { getServices } from "../../../service";
import { motion } from "framer-motion";
import useScrollFromTop from "../../../hooks/useScrollFromTop";
import { GoToCartButton } from "../../molecules/go-to-cart-action/GoToCartButton";
import { useAppContext } from "../../../contexts/useAppContext";
import { LoadingScreen } from "../../templates/loading-screen/LoadingScreen";
import useScrollToTop from "../../../hooks/useScrollToTop";
import { createDaysArray } from "../../../utils";
import { Button, Flex } from "antd";

const CostumAddButton2 = styled(Button)`
  margin-top: 30px;
  max-width: 100% !important;
  width: 70% !important;
  height: 48px !important;
  font-size: 18px;
  border-radius: 40px;
  color: white;
  background: #4f46e5;
  border-color: #4f46e5;

  &:hover {
    color: white !important;
    background: #4f46e550 !important;
  }
`;

export const ThankYouPage: React.FC = () => {
  useScrollToTop();
  const { scrollTop } = useScrollFromTop();
  const { cart } = useAppContext();

  const { data = [], isFetching } = useQuery(
    ["/services", 1],
    () => getServices(1),
    { enabled: !!true }
  );

  const animations = {
    initial: { opacity: 0, x: "10vw" },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0 },
  };

  if (!isFetching && data) {
    return (
      <>
        <AnimadetPage>
          <div
            style={
              {
                // paddingBottom: cart.length > 0 ? "100px" : "0px",
              }
            }
          >
            <motion.div
              variants={animations}
              initial="initial"
              animate="animate"
              exit="exit"
              style={
                {
                  // paddingTop: scrollTop > 64 ? "35px" : "0px",
                }
              }
            >
              <Flex
                justify="center"
                align="center"
                style={{
                  height: "calc(100vh - 44px)",
                  width: "100%",
                  flexDirection: "column",
                  paddingLeft: "10%",
                  paddingRight: "10%",
                }}
              >
                <P
                  $size="20"
                  $weight="600"
                  $height="16"
                  style={{
                    textAlign: "center",
                    marginBottom: "30px",
                  }}
                >
                  Dziękujemy za Twój zakup!
                </P>
                <P
                  $size="16"
                  $weight="300"
                  $height="16"
                  style={{
                    textAlign: "center",
                  }}
                >
                  Twoja transakcja została pomyslnie zrealizowana. Wkrótce
                  otrzymasz e-mail z potwierdzeniem zamówienia i szczegółami
                  transakcji
                </P>
                <CostumAddButton2 onClick={() => window.location.reload()}>
                  <P $size="16" $height="20" $weight="400" $family="Lato-Light">
                    Strona początkowa
                  </P>
                </CostumAddButton2>
              </Flex>
            </motion.div>
          </div>
        </AnimadetPage>
      </>
    );
  }

  return <LoadingScreen />;
};

import React from "react";
import {
  TextInput,
  DataPicker,
  Select,
  Checkbox,
  NumberInput,
  Addition,
} from "../../atoms";
import { ChangeReservationDate } from "../../atoms/change-reservation-date/ChangeReservationDate";
import { SetDeliveryTime } from "../../atoms/set-delivety-time/SetDeliveryTime";
import { AdditionSelect } from "../../atoms/addition-select/AdditionSelect";

const COMPONENT: { [key: string]: React.ComponentType<any> } = {
  checkbox: Checkbox,
  select: Select,
  addition: Addition,
  "multi-select": Select,
  "input-text": TextInput,
  "data-picker": DataPicker,
  "number-input": NumberInput,
  multiSelect: Select,
  inputText: TextInput,
  dataPicker: DataPicker,
  numberInput: NumberInput,
  changeReservationDate: ChangeReservationDate,
  setDeliveryTime: SetDeliveryTime,
  additionSelect: AdditionSelect,
};

type SupportedControlsPropsType = {
  control: any;
  finalPrice?: any;
  setFinalPrice?: any;
};

export const SupportedControls: React.FC<SupportedControlsPropsType> = (
  props
) => {
  if (!props?.control?.field_type) {
    return null;
  }

  const Component = COMPONENT[props.control.field_type];

  if (!Component) {
    return <>{`Invalid field_type: ${props.control.field_type}`}</>;
  }

  return <Component {...props} />;
};

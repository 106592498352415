import { Avatar, Col, Flex, Space } from "antd";
import React from "react";

import styled from "styled-components";
import { Labels } from "../labels/Labels";
import { P } from "../../atoms/paragraph/Paragraph.styled";
import test from "../../../images/meal.jpg";
import { useAppContext } from "../../../contexts/useAppContext";

const ListMeal = styled.div`
  border: 1px solid #f3f4f6;
  border-radius: 24px;
  margin-bottom: 10px;
  background: white;
  box-shadow: 1px 3px 0px 0px #edebfb;
  padding: 16px;
  cursor: pointer;
`;

const GridMeal = styled.div`
  border: 1px solid #f3f4f6;
  border-radius: 24px;
  margin-bottom: 10px;
  background: white;
  box-shadow: 1px 3px 0px 0px #edebfb;
  padding: 16px;
  cursor: pointer;
  width: calc(50% - 4px);

  @media (max-width: 700px) {
    width: 100%;
  }
`;

const Meal = ({
  listType,
  children,
  onClick,
}: {
  listType: "LIST" | "GRID";
  children: React.ReactNode;
  onClick: () => void;
}) => {
  switch (listType) {
    case "LIST":
      return <ListMeal onClick={onClick}>{children}</ListMeal>;
    case "GRID":
      return <GridMeal onClick={onClick}>{children}</GridMeal>;
    default:
      return <ListMeal onClick={onClick}>{children}</ListMeal>;
  }
};

const ImageContainer = styled.div<{ type: "LIST" | "GRID" }>`
  width: ${(props) => (props.type === "GRID" ? "100%" : "110px")};
  height: ${(props) => (props.type === "GRID" ? "auto" : "110px")};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &::before {
    content: "";
    display: block;
    padding-top: 100%; /* Ensures a square aspect ratio */
  }
`;

const StyledImage = styled.img<{ type: "LIST" | "GRID" }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* Takes 100% width */
  height: 100%; /* Matches the width in pixels */
  object-fit: cover; /* Ensures the image covers the entire area */
  border-radius: 12px;
`;

export const SingleMeal: React.FC<{ meal: any; onClick?: any }> = ({
  meal,
  onClick,
}) => {
  const { selectedBranch } = useAppContext();
  const listType = selectedBranch?.listType ?? "LIST";

  const price = meal.price ?? meal.basePrice;
  return (
    <Meal
      listType={listType}
      onClick={() => {
        onClick && onClick(meal);
      }}
    >
      <Flex
        justify="space-between"
        style={{
          marginBottom: "15px",
        }}
      >
        {listType === "LIST" && (
          <Flex vertical>
            <P $size="18" $height="24" $weight="400">
              {meal.name}
            </P>
            <P $size="14" $height="20" $weight="300" $family="Lato-Light">
              {meal.description}
            </P>
          </Flex>
        )}
        <ImageContainer type={listType}>
          <StyledImage src={test} type={listType} />
        </ImageContainer>
      </Flex>
      {listType === "GRID" && (
        <Flex
          justify="start"
          style={{
            marginBottom: "15px",
          }}
          vertical
          gap={5}
        >
          <P $size="18" $height="24" $weight="400">
            {meal.name}
          </P>
          <P $size="14" $height="20" $weight="300" $family="Lato-Light">
            {meal.description}
          </P>
          <Labels />
        </Flex>
      )}

      <Flex justify={listType === "LIST" ? "space-between" : "end"}>
        <Space>
          <P
            $size="18"
            $height="24"
            $weight="400"
            style={{
              color: "#4f46e5",
              margin: "0px",
              whiteSpace: "nowrap",
            }}
          >
            {(price / 100).toFixed(2)} zł
          </P>
        </Space>
        {listType === "LIST" && <Labels />}
      </Flex>
    </Meal>
  );
};

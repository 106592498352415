import { ReactNode, useState } from "react";
import { AppContext } from "./AppContext";
import React from "react";
export const AppContextProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  // branch restauracji
  const [selectedBranch, setSelectedBranch] = useState(null);
  // koszyk
  const [cart, setCart] = useState<any>([]);
  // czas dla usług i typ dostawy dla zamówień
  const [additionalSections, setAdditionalSections] = useState(null);
  const [deliveryType, setDeliveryType] = useState("TO_THE_ADDRESS");
  const [deliveryTimeType, setDeliveryTimeType] = useState("NOW"); // NOW / FUTURE
  const [orderDate, setOrderDate] = useState<any>(null);
  const [orderTime, setOrderTime] = useState<any>(null);
  // czas dla usług
  const [serviceData, setServiceData] = useState<any>(null);
  const [serviceTime, setServiceTime] = useState<any>(null);
  const [selectedCategory, setSelectedCategory] = useState<any>(null);
  const [serviceResurces, setServiceResurces] = useState<any>({});
  // google maps
  const [deliveryAdress, setDeliveryAdress] = useState<any>();
  const [selectedDeliveryMethod, setSelectedDeliveryMethod] = useState<any>();
  const initApertmenNumber = localStorage.getItem("apertmenNumber");
  const [apertmenNumber, setApertmenNumber] = useState<any>(
    initApertmenNumber ?? ""
  );
  const initDdeliveryNote = localStorage.getItem("deliveryNote");
  const [deliveryNote, setDeliveryNote] = useState<any>(
    initDdeliveryNote ?? ""
  );
  // dane zawsze obowiązkowe
  const initName = localStorage.getItem("name");
  const [name, setName] = useState<any>(initName ?? "");
  const initEmail = localStorage.getItem("email");
  const [email, setEmail] = useState<any>(initEmail ?? "");
  const initNumber = localStorage.getItem("number");
  const [number, setNumber] = useState<any>(initNumber ?? "");

  const [mealName, setMealName] = useState<any>("");
  return (
    <AppContext.Provider
      value={{
        cart,
        setCart,
        mealName,
        setMealName,
        selectedBranch,
        setSelectedBranch,
        deliveryType,
        setDeliveryType,
        additionalSections,
        setAdditionalSections,
        serviceData,
        setServiceData,
        serviceTime,
        setServiceTime,
        selectedCategory,
        setSelectedCategory,
        deliveryTimeType,
        setDeliveryTimeType,
        orderDate,
        setOrderDate,
        orderTime,
        setOrderTime,
        deliveryAdress,
        setDeliveryAdress,
        apertmenNumber,
        setApertmenNumber,
        deliveryNote,
        setDeliveryNote,
        selectedDeliveryMethod,
        setSelectedDeliveryMethod,
        name,
        setName,
        email,
        setEmail,
        number,
        setNumber,
        serviceResurces, 
        setServiceResurces
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

import React from "react";
import styled from "styled-components";
import { P } from "../../atoms/paragraph/Paragraph.styled";
import { Col, Flex, Form, Row } from "antd";
import { useAppContext } from "../../../contexts/useAppContext";
import { useNavigate } from "react-router-dom";

const SingleBranch = styled.div<{ $selected: boolean }>`
  max-width: 1000px;
  border-radius: 8px;
  min-height: 64px;
  cursor: pointer;
  margin-bottom: 10px;
  box-sizing: border-box;
  outline: ${({ $selected }) =>
    $selected ? "2px solid #4f46e5" : "1px solid #d1d5db"};
  background: white;
`;

const Dot = styled.div<{ $selected: boolean }>`
  width: 16px;
  height: 16px;
  position: absolute;
  top: 12px;
  right: 10px;
  border-radius: 100%;
  border: ${({ $selected }) =>
    $selected ? "4px solid #4f46e5" : "1px solid #d1d5db"};
  box-sizing: border-box;
  background: white;
`;

export const DeliveryTimePicker: React.FC = () => {
  const { setDeliveryTimeType, deliveryTimeType, deliveryType, orderDate, orderTime } =
    useAppContext();
  const navigate = useNavigate();
  return (
    <Form layout="vertical">
      <Form.Item label={"Termin dostawy "}>
        <SingleBranch
          $selected={deliveryTimeType === "NOW"}
          onClick={() => setDeliveryTimeType("NOW")}
        >
          <Row>
            <Col
              span={1}
              style={{
                position: "relative",
              }}
            >
              <Dot $selected={deliveryTimeType === "NOW"} />
            </Col>
            <Col span={23}>
              <Flex
                align="start"
                justify="center"
                vertical
                style={{
                  height: "64px",
                  minHeight: "64px",
                }}
              >
                <P
                  $size="14"
                  $height="16"
                  $weight="400"
                  $family="Lato"
                  style={{
                    marginBottom: deliveryTimeType === "NOW" ? "5px" : "24px",
                  }}
                >
                  {"Jak najszybciej"}
                </P>
                {deliveryTimeType === "NOW" && (
                  <P $size="12" $height="20" $weight="300" $family="Lato-Light">
                    {"Średni czas oczekiwania: ~60 min"}
                  </P>
                )}
              </Flex>
            </Col>
          </Row>
        </SingleBranch>
        <SingleBranch
          $selected={deliveryTimeType === "FUTURE"}
          onClick={() => navigate("/delivery-date")}
        >
          <Row>
            <Col
              span={1}
              style={{
                position: "relative",
              }}
            >
              <Dot $selected={deliveryTimeType === "FUTURE"} />
            </Col>
            <Col span={23}>
              <Flex
                align="start"
                justify="center"
                vertical
                style={{
                  height: "64px",
                  minHeight: "64px",
                }}
              >
                <P
                  $size="14"
                  $height="16"
                  $weight="400"
                  $family="Lato"
                  style={{
                    marginBottom: deliveryTimeType !== "FUTURE" ? "24px" : "5px",
                  }}
                >
                  {deliveryType === "TO_THE_ADDRESS"
                    ? "Zaplanuj dostawę"
                    : "Zaplanuj odbiór"}
                </P>
                {deliveryTimeType === "FUTURE" && (
                  <P $size="12" $height="20" $weight="300" $family="Lato-Light">
                    {deliveryType === "SELF_PICKUP"
                      ? `Zaplano odbiór: ${orderDate} ${orderTime}`
                      : `Zaplano dostawe: ${orderDate} ${orderTime}`}
                  </P>
                )}
              </Flex>
            </Col>
          </Row>
        </SingleBranch>
      </Form.Item>
    </Form>
  );
};
